import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import App from './_generated/v3/app/App';
import {MP} from '@newageerp/crm-ui'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <MP.GlobalProvider settings={{
    baseUrl: 'https://m.crm.wingmanx.ai/api/storage',
    apiKey: '5aa8f006-1d28-4d84-8b86-c25449cf645d'
  }}>
    <App />
  </MP.GlobalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

