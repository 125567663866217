import { CustomEditComponentsMap } from "../_generated/_custom/edit/CustomEditComponentsMap";
import { CustomListComponentsMap } from "../_generated/_custom/tabs/CustomListComponentsMap";
import { CustomViewComponentsMap } from "../_generated/_custom/view/CustomViewComponentsMap";
import { CustomAuthForm } from "./CustomAuth/CustomAuthForm";
import MpEndPoint from "./Mysql/MpEndPoint";
import UsersMenuItem from "./Users/UsersMenuItem";
import UsersMenuItemAuth from "./Users/UsersMenuItemAuth";

export const PluginsMap: any = {
    ...CustomViewComponentsMap,
    ...CustomEditComponentsMap,
    ...CustomListComponentsMap,

    'custom.user.genToken': UsersMenuItem,
    'custom.user.genTokenAuth': UsersMenuItemAuth,
    
    'custom.routes': {
        '/c/custom-sign-up': CustomAuthForm,
        '/c/mp': MpEndPoint
    }
}