import { INaeProperty } from "@newageerp/v3.app.main-bundle";
export const NaeSProperties: INaeProperty[] = [
    {
        "schema": "achievement",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "code",
        "additionalProperties": [],
        "description": "",
        "className": "Achievement",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "achievement",
        "key": "descirption",
        "type": "string",
        "format": "",
        "title": "descirption",
        "additionalProperties": [],
        "description": "",
        "className": "Achievement",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "achievement",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Achievement",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "achievement",
        "key": "image",
        "type": "string",
        "format": "",
        "title": "image",
        "additionalProperties": [],
        "description": "",
        "className": "Achievement",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "achievement",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "title",
        "additionalProperties": [],
        "description": "",
        "className": "Achievement",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "allUnlocked",
        "type": "boolean",
        "format": "",
        "title": "All unlocked",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "app-user",
        "key": "chaptersFlow",
        "type": "string",
        "format": "",
        "title": "chaptersFlow",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "completedAtLeastOneLesson",
        "type": "integer",
        "format": "",
        "title": "Completed at least one lesson",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "app-user",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "Created At",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "createdAtDate",
        "type": "string",
        "format": "",
        "title": "Created At",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "createdAtMonth",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "app-user",
        "key": "isRegistered",
        "type": "integer",
        "format": "",
        "title": "Is registered",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": false,
        "dbType": "",
        "as": "bool",
        "naeType": "number"
    },
    {
        "schema": "app-user",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "needCartSync",
        "type": "boolean",
        "format": "",
        "title": "needCartSync",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "app-user",
        "key": "needSessionSync",
        "type": "boolean",
        "format": "",
        "title": "needSessionSync",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "app-user",
        "key": "orderId",
        "type": "string",
        "format": "",
        "title": "Order ID",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "orderOrigin",
        "type": "string",
        "format": "",
        "title": "Order origin",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "passwordHash",
        "type": "string",
        "format": "",
        "title": " passwordHash",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "relevantTopics",
        "type": "array",
        "format": "string",
        "title": "relevantTopics",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "app-user",
        "key": "relevantTopicsAsString",
        "type": "string",
        "format": "",
        "title": "Relevant topics",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "showReviewOnHomePage",
        "type": "boolean",
        "format": "",
        "title": "showReviewOnHomePage",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "app-user",
        "key": "signUpAbVersion",
        "type": "string",
        "format": "",
        "title": "Sign Up AbVersion",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "userLessons",
        "type": "array",
        "format": "challenge-user-lesson",
        "title": "userLessons",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "app-user",
        "key": "uuid",
        "type": "string",
        "format": "",
        "title": " UUID",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "app-user-relationship",
        "key": "appUser",
        "type": "rel",
        "format": "app-user",
        "title": " appUser",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserRelationship",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "app-user-relationship",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": " createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserRelationship",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "app-user-relationship",
        "key": "emotion",
        "type": "array",
        "format": "string",
        "title": "emotion",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserRelationship",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "app-user-relationship",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserRelationship",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "app-user-relationship",
        "key": "type",
        "type": "string",
        "format": "",
        "title": " type",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserRelationship",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "article",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Article",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "article",
        "key": "author",
        "type": "string",
        "format": "",
        "title": " author",
        "additionalProperties": [],
        "description": "",
        "className": "Article",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "article",
        "key": "code",
        "type": "string",
        "format": "",
        "title": " code",
        "additionalProperties": [],
        "description": "",
        "className": "Article",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "article",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": " date",
        "additionalProperties": [],
        "description": "",
        "className": "Article",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "article",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": " description",
        "additionalProperties": [],
        "description": "",
        "className": "Article",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "article",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Article",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "article",
        "key": "image",
        "type": "string",
        "format": "",
        "title": " image",
        "additionalProperties": [],
        "description": "",
        "className": "Article",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "article",
        "key": "sort",
        "type": "integer",
        "format": "",
        "title": "sort",
        "additionalProperties": [],
        "description": "",
        "className": "Article",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "article",
        "key": "title",
        "type": "string",
        "format": "",
        "title": " title",
        "additionalProperties": [],
        "description": "",
        "className": "Article",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "article",
        "key": "topic",
        "type": "string",
        "format": "",
        "title": "topic",
        "additionalProperties": [],
        "description": "",
        "className": "Article",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "article-user",
        "key": "appUser",
        "type": "rel",
        "format": "app-user",
        "title": "appUser",
        "additionalProperties": [],
        "description": "",
        "className": "ArticleUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "article-user",
        "key": "article",
        "type": "rel",
        "format": "article",
        "title": "article",
        "additionalProperties": [],
        "description": "",
        "className": "ArticleUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "article-user",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ArticleUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "article-user",
        "key": "isSaved",
        "type": "boolean",
        "format": "",
        "title": "isSaved",
        "additionalProperties": [],
        "description": "",
        "className": "ArticleUser",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "article-user",
        "key": "ratingData",
        "type": "array",
        "format": "string",
        "title": "ratingData",
        "additionalProperties": [],
        "description": "",
        "className": "ArticleUser",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "article-user",
        "key": "savedAt",
        "type": "string",
        "format": "date-time",
        "title": "savedAt",
        "additionalProperties": [],
        "description": "",
        "className": "ArticleUser",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "challenge",
        "key": "chaptersFlow",
        "type": "string",
        "format": "",
        "title": "chaptersFlow",
        "additionalProperties": [],
        "description": "",
        "className": "Challenge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "challenge",
        "key": "code",
        "type": "string",
        "format": "",
        "title": " code",
        "additionalProperties": [],
        "description": "",
        "className": "Challenge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "challenge",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": " description",
        "additionalProperties": [],
        "description": "",
        "className": "Challenge",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "challenge",
        "key": "globalNumber",
        "type": "integer",
        "format": "",
        "title": "globalNumber",
        "additionalProperties": [],
        "description": "",
        "className": "Challenge",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "challenge",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Challenge",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "challenge",
        "key": "image",
        "type": "string",
        "format": "",
        "title": " image",
        "additionalProperties": [],
        "description": "",
        "className": "Challenge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "challenge",
        "key": "lessons",
        "type": "array",
        "format": "challenge-lesson",
        "title": " lessons",
        "additionalProperties": [],
        "description": "",
        "className": "Challenge",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "challenge",
        "key": "subTitle",
        "type": "string",
        "format": "",
        "title": "subTitle",
        "additionalProperties": [],
        "description": "",
        "className": "Challenge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "challenge",
        "key": "title",
        "type": "string",
        "format": "",
        "title": " title",
        "additionalProperties": [],
        "description": "",
        "className": "Challenge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "challenge-lesson",
        "key": "challenge",
        "type": "rel",
        "format": "challenge",
        "title": " challenge",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeLesson",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "challenge-lesson",
        "key": "code",
        "type": "string",
        "format": "",
        "title": " code",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeLesson",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "challenge-lesson",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "description",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeLesson",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "challenge-lesson",
        "key": "globalLessonNumber",
        "type": "integer",
        "format": "",
        "title": "globalLessonNumber",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeLesson",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "challenge-lesson",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeLesson",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "challenge-lesson",
        "key": "image",
        "type": "string",
        "format": "",
        "title": "image",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeLesson",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "challenge-lesson",
        "key": "learningTime",
        "type": "string",
        "format": "",
        "title": "learningTime",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeLesson",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "challenge-lesson",
        "key": "lessonNumber",
        "type": "integer",
        "format": "",
        "title": "lessonNumber",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeLesson",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "challenge-lesson",
        "key": "rate",
        "type": "number",
        "format": "float",
        "title": "rate",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeLesson",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "challenge-lesson",
        "key": "ratingCount",
        "type": "integer",
        "format": "",
        "title": "ratingCount",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeLesson",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "challenge-lesson",
        "key": "title",
        "type": "string",
        "format": "",
        "title": " title",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeLesson",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "challenge-user",
        "key": "appUser",
        "type": "rel",
        "format": "app-user",
        "title": " appUser",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "challenge-user",
        "key": "challenge",
        "type": "rel",
        "format": "challenge",
        "title": " challenge",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "challenge-user",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": " createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUser",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "challenge-user",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "challenge-user-achievement",
        "key": "achievement",
        "type": "rel",
        "format": "achievement",
        "title": "achievement",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserAchievement",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "challenge-user-achievement",
        "key": "appUser",
        "type": "rel",
        "format": "app-user",
        "title": "appUser",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserAchievement",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "challenge-user-achievement",
        "key": "challenge",
        "type": "rel",
        "format": "challenge",
        "title": "challenge",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserAchievement",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "challenge-user-achievement",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserAchievement",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "challenge-user-achievement",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserAchievement",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "challenge-user-lesson",
        "key": "appUser",
        "type": "rel",
        "format": "app-user",
        "title": " appUser",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLesson",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "challenge-user-lesson",
        "key": "challenge",
        "type": "rel",
        "format": "challenge",
        "title": " challenge",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLesson",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "challenge-user-lesson",
        "key": "completedAt",
        "type": "string",
        "format": "date-time",
        "title": "completedAt",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLesson",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "challenge-user-lesson",
        "key": "completedAtDate",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLesson",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "challenge-user-lesson",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLesson",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "challenge-user-lesson",
        "key": "isCompleted",
        "type": "boolean",
        "format": "",
        "title": " isCompleted",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLesson",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "challenge-user-lesson",
        "key": "isLocked",
        "type": "boolean",
        "format": "",
        "title": "isLocked",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLesson",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "challenge-user-lesson",
        "key": "isProgress",
        "type": "boolean",
        "format": "",
        "title": "isProgress",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLesson",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "challenge-user-lesson",
        "key": "isSaved",
        "type": "boolean",
        "format": "",
        "title": "isSaved",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLesson",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "challenge-user-lesson",
        "key": "lesson",
        "type": "rel",
        "format": "challenge-lesson",
        "title": " lesson",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLesson",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "challenge-user-lesson",
        "key": "progressAt",
        "type": "string",
        "format": "date-time",
        "title": "progressAt",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLesson",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "challenge-user-lesson",
        "key": "ratingData",
        "type": "array",
        "format": "string",
        "title": "ratingData",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLesson",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "challenge-user-lesson",
        "key": "savedAt",
        "type": "string",
        "format": "date-time",
        "title": "savedAt",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLesson",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "challenge-user-lesson",
        "key": "unlockAt",
        "type": "string",
        "format": "date-time",
        "title": "unlockAt",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLesson",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "challenge-user-lesson",
        "key": "userData",
        "type": "array",
        "format": "string",
        "title": "userData",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLesson",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "challenge-user-lesson-note",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLessonNote",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "challenge-user-lesson-note",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "description",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLessonNote",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "challenge-user-lesson-note",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLessonNote",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "challenge-user-lesson-note",
        "key": "userLesson",
        "type": "rel",
        "format": "challenge-user-lesson",
        "title": "userLesson",
        "additionalProperties": [],
        "description": "",
        "className": "ChallengeUserLessonNote",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "diary-note",
        "key": "appUser",
        "type": "rel",
        "format": "app-user",
        "title": "appUser",
        "additionalProperties": [],
        "description": "",
        "className": "DiaryNote",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "diary-note",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "DiaryNote",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "diary-note",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "description",
        "additionalProperties": [],
        "description": "",
        "className": "DiaryNote",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "diary-note",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "DiaryNote",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "diary-note",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "title",
        "additionalProperties": [],
        "description": "",
        "className": "DiaryNote",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-folder",
        "key": "sort",
        "type": "number",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataFolder",
        "isDb": true,
        "dbType": "int",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-folder",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataFolder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "columns",
        "type": "string",
        "format": "text",
        "title": "columns",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "exploreId",
        "type": "string",
        "format": "",
        "title": "exploreId",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "folder",
        "type": "rel",
        "format": "sf-explore-data-folder",
        "title": "Folder",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sort",
        "type": "number",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sqlCount",
        "type": "string",
        "format": "text",
        "title": "sqlCount",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sqlData",
        "type": "string",
        "format": "text",
        "title": "sqlData",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "title",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "tip",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "code",
        "additionalProperties": [],
        "description": "",
        "className": "Tip",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "tip",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Tip",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "tip",
        "key": "image",
        "type": "string",
        "format": "",
        "title": "image",
        "additionalProperties": [],
        "description": "",
        "className": "Tip",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "tip",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "title",
        "additionalProperties": [],
        "description": "",
        "className": "Tip",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    }
]